<template>
  <div class="nav-menu" :class="{ 'nav-menu-open': isOpen }">
    <div class="menu-overlay" v-if="isOpen" @click="toggleMenu"></div>
    <div class="menu-toggle" @click="toggleMenu">
      <div class="menu-icon">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="menu-content">
      <div class="menu-header">
        <div class="menu-emoji">💑</div>
        <h2>我们的回忆录</h2>
      </div>
      <div class="menu-items">
        <router-link
          v-for="user in users"
          :key="user.id"
          :to="`/memoir/${user.user}`"
          class="menu-item"
          @click="toggleMenu">
          <span class="item-icon">{{ user.icon }}</span>
          <span class="item-text">{{ user.name }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useUsers } from '@/composables/useUsers'

const isOpen = ref(false)
const { users, fetchUsers } = useUsers()

const toggleMenu = () => {
  isOpen.value = !isOpen.value
}

onMounted(async () => {
  await fetchUsers()
})
</script>

<style lang="scss" scoped>
.nav-menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 1000;

  .menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 998;
    backdrop-filter: blur(2px);
    transition: opacity 0.3s ease;
  }

  .menu-toggle {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background: #ff69b4;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 10px rgba(255, 105, 180, 0.3);
    z-index: 1001;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }

    .menu-icon {
      width: 20px;
      height: 16px;
      position: relative;

      span {
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: white;
        border-radius: 2px;
        transition: all 0.3s ease;

        &:nth-child(1) {
          top: 0;
        }
        &:nth-child(2) {
          top: 7px;
        }
        &:nth-child(3) {
          top: 14px;
        }
      }
    }
  }

  .menu-content {
    position: fixed;
    top: 0;
    right: -300px;
    width: 300px;
    height: 100vh;
    background: white;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
    transition: right 0.3s ease;
    overflow-y: auto;
    z-index: 999;

    .menu-header {
      text-align: center;
      padding: 20px;
      border-bottom: 2px solid #ffd1dc;
      margin-bottom: 20px;

      .menu-emoji {
        font-size: 3em;
        margin-bottom: 15px;
        animation: bounce 2s infinite;
      }

      h2 {
        color: #ff69b4;
        margin: 0;
        font-size: 1.5em;
      }
    }

    .menu-items {
      padding: 0 20px;

      .menu-item {
        display: flex;
        align-items: center;
        padding: 15px;
        margin: 10px 0;
        border-radius: 12px;
        text-decoration: none;
        color: #666;
        transition: all 0.3s ease;
        background: #fff5f7;

        &:hover,
        &.router-link-active {
          background: #ff69b4;
          color: white;
          transform: translateX(10px);
        }

        .item-icon {
          font-size: 1.5em;
          margin-right: 15px;
        }

        .item-text {
          font-size: 1.1em;
        }
      }
    }
  }

  &.nav-menu-open {
    .menu-content {
      right: 0;
    }

    .menu-toggle .menu-icon {
      span {
        &:nth-child(1) {
          transform: rotate(45deg);
          top: 7px;
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
          top: 7px;
        }
      }
    }
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
</style>
