<template>
  <div class="search-wrapper">
    <div class="search-container">
      <div class="search-bar">
        <input
          type="text"
          v-model="searchQuery"
          placeholder="搜索标题、描述或日期..."
          @input="handleSearch" />
        <span class="search-icon">🔍</span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'

export default {
  props: {
    memoirs: {
      type: Array,
      required: true,
    },
  },
  emits: ['search-results'],
  setup(props, { emit }) {
    const searchQuery = ref('')

    const handleSearch = () => {
      if (!searchQuery.value.trim()) {
        emit('search-results', props.memoirs)
        return
      }

      const query = searchQuery.value.toLowerCase()
      const results = props.memoirs.filter(memoir => {
        const matchTitle = memoir.title?.toLowerCase().includes(query)
        const matchDate = memoir.time?.includes(query)
        const matchDesc = memoir.description?.some(desc => desc?.toLowerCase().includes(query))

        return matchTitle || matchDate || matchDesc
      })

      emit('search-results', results)
    }

    watch(() => props.memoirs, handleSearch)

    return {
      searchQuery,
      handleSearch,
    }
  },
}
</script>

<style lang="scss" scoped>
.search-wrapper {
  position: sticky;
  top: 0;
  z-index: 100;
  padding: 15px 0;
  margin: 20px -20px;

  .search-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px;

    .search-bar {
      position: relative;
      width: 100%;

      input {
        width: 100%;
        padding: 12px 40px 12px 15px;
        border: 2px solid #ffd1dc;
        border-radius: 25px;
        font-size: 1.1em;
        outline: none;
        transition: all 0.3s ease;
        background: white;

        &:focus {
          border-color: #ff69b4;
          box-shadow: 0 0 10px rgba(255, 105, 180, 0.2);
        }
      }

      .search-icon {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1.2em;
        color: #ff69b4;
      }
    }
  }
}

@media (max-width: 768px) {
  .search-wrapper {
    padding: 10px 0;
    margin: 15px -10px;

    .search-container {
      padding: 0 10px;
    }
  }
}
</style>
