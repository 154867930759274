<template>
  <transition name="fade">
    <button v-show="visible" class="back-to-top" @click="scrollToTop" title="返回顶部">
      <span>↑</span>
    </button>
  </transition>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'

export default {
  setup() {
    const visible = ref(false)

    const checkScroll = () => {
      visible.value = window.pageYOffset > 200
    }

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    onMounted(() => {
      window.addEventListener('scroll', checkScroll)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', checkScroll)
    })

    return {
      visible,
      scrollToTop,
    }
  },
}
</script>

<style lang="scss" scoped>
.back-to-top {
  position: fixed;
  bottom: 90px; // 位置在菜单按钮上方
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #ff69b4;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  box-shadow: 0 2px 10px rgba(255, 105, 180, 0.3);
  transition: all 0.3s ease;
  z-index: 999;

  span {
    line-height: 1;
    margin-bottom: 2px;
  }

  &:hover {
    transform: translateY(-5px);
    background: #ff1493;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
</style>
