<template>
  <teleport to="body">
    <div class="image-preview-container" v-if="visible">
      <div class="preview-overlay" @click="close"></div>
      <div class="preview-content">
        <div class="image-wrapper">
          <img :src="currentImage" :alt="currentIndex + 1" @load="onImageLoad" :class="imageClass" />
        </div>
        <div class="preview-controls">
          <button class="control-btn prev-btn" @click="prevImage" v-show="images.length > 1">‹</button>
          <button class="control-btn next-btn" @click="nextImage" v-show="images.length > 1">›</button>
        </div>
        <div class="preview-counter" v-if="images.length > 1">
          {{ currentIndex + 1 }} / {{ images.length }}
        </div>
        <button class="close-btn" @click="close">×</button>
      </div>
    </div>
  </teleport>
</template>

<script>
import { ref, watch, computed, onMounted, onUnmounted } from 'vue'

export default {
  props: {
    visible: Boolean,
    images: Array,
    initialIndex: {
      type: Number,
      default: 0,
    },
  },

  emits: ['update:visible'],

  setup(props, { emit }) {
    const currentIndex = ref(props.initialIndex)
    const imageClass = ref('')

    watch(
      () => props.visible,
      newVal => {
        if (newVal) {
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = ''
        }
      }
    )

    watch(
      () => props.initialIndex,
      newVal => {
        currentIndex.value = newVal
      }
    )

    const currentImage = computed(() => props.images[currentIndex.value])

    const onImageLoad = event => {
      const img = event.target
      const ratio = img.naturalWidth / img.naturalHeight
      if (ratio < 0.8) {
        imageClass.value = 'tall-image'
      } else if (ratio > 1.2) {
        imageClass.value = 'wide-image'
      } else {
        imageClass.value = 'square-image'
      }
    }

    const nextImage = () => {
      currentIndex.value = (currentIndex.value + 1) % props.images.length
      imageClass.value = '' // 重置图片类型
    }

    const prevImage = () => {
      currentIndex.value = (currentIndex.value - 1 + props.images.length) % props.images.length
      imageClass.value = '' // 重置图片类型
    }

    const close = () => {
      emit('update:visible', false)
      imageClass.value = '' // 重置图片类型
    }

    const handleKeydown = e => {
      if (!props.visible) return
      switch (e.key) {
        case 'ArrowLeft':
          prevImage()
          break
        case 'ArrowRight':
          nextImage()
          break
        case 'Escape':
          close()
          break
      }
    }

    onMounted(() => {
      window.addEventListener('keydown', handleKeydown)
    })

    onUnmounted(() => {
      window.removeEventListener('keydown', handleKeydown)
      document.body.style.overflow = ''
    })

    return {
      currentIndex,
      currentImage,
      imageClass,
      nextImage,
      prevImage,
      close,
      onImageLoad,
    }
  },
}
</script>

<style lang="scss" scoped>
.image-preview-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;

  .preview-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(5px);
  }

  .preview-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;

    .image-wrapper {
      max-width: 90vw;
      max-height: 90vh;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: auto;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        user-select: none;
        -webkit-user-drag: none;

        &.tall-image {
          max-height: 90vh;
          width: auto;
        }

        &.wide-image {
          max-width: 90vw;
          height: auto;
        }

        &.square-image {
          max-width: 80vw;
          max-height: 80vh;
        }
      }
    }

    .preview-controls {
      position: fixed;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      display: flex;
      justify-content: space-between;
      padding: 0 40px;
      pointer-events: none;
      z-index: 10001;

      .control-btn {
        pointer-events: auto;
        background: rgba(0, 0, 0, 0.5);
        border: none;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: white;
        font-size: 60px;
        line-height: 1;
        transition: all 0.3s ease;
        backdrop-filter: blur(5px);
        padding-bottom: 8px;

        &:hover {
          transform: scale(1.1);
          background: rgba(0, 0, 0, 0.7);
        }
      }
    }

    .preview-counter {
      position: fixed;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      color: white;
      font-size: 16px;
      background: rgba(0, 0, 0, 0.5);
      padding: 8px 20px;
      border-radius: 20px;
      backdrop-filter: blur(5px);
      z-index: 10001;
    }

    .close-btn {
      position: fixed;
      top: 20px;
      right: 20px;
      background: rgba(0, 0, 0, 0.5);
      border: none;
      color: white;
      font-size: 36px;
      cursor: pointer;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease;
      backdrop-filter: blur(5px);
      z-index: 10001;

      &:hover {
        transform: scale(1.1);
        background: rgba(0, 0, 0, 0.7);
      }
    }
  }
}

@media (max-width: 768px) {
  .image-preview-container {
    .preview-content {
      .preview-controls {
        padding: 0 10px;

        .control-btn {
          width: 40px;
          height: 40px;
          font-size: 40px;
        }
      }

      .close-btn {
        top: 10px;
        right: 10px;
        width: 40px;
        height: 40px;
        font-size: 24px;
      }

      .preview-counter {
        bottom: 20px;
        font-size: 14px;
        padding: 6px 15px;
      }
    }
  }
}
</style>
