import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App)

// 开发环境下启用 devtools
if (process.env.NODE_ENV === 'development') {
  app.config.devtools = true
}

app.use(router)
app.mount('#app') 