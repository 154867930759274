import { ref } from 'vue'
import { memoirService } from '@/utils/request'

export function useMemoirs () {
  const memoirs = ref([])
  const loading = ref(false)
  const error = ref(null)

  const fetchMemoirs = async (username) => {
    memoirs.value = []
    error.value = null
    loading.value = true

    try {
      const response = await memoirService.get(`/photo/list?username=${username}`)
      await new Promise(resolve => setTimeout(resolve, 500))
      memoirs.value = response.data || []
    } catch (e) {
      error.value = '获取回忆录数据失败'
      console.error(e)
    } finally {
      loading.value = false
    }
  }

  return {
    memoirs,
    loading,
    error,
    fetchMemoirs
  }
} 