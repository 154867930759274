import axios from 'axios'

// 创建两个不同的 axios 实例，分别对应两个不同的基础 URL
const userService = axios.create({
  baseURL: 'https://hyl.xuying.vip',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  }
})

const memoirService = axios.create({
  baseURL: 'https://hyljava.xuying.vip',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  }
})

// 响应拦截器
userService.interceptors.response.use(
  response => response.data,
  error => {
    console.log('err' + error)
    return Promise.reject(error)
  }
)

memoirService.interceptors.response.use(
  response => response.data,
  error => {
    console.log('err' + error)
    return Promise.reject(error)
  }
)

export { userService, memoirService } 