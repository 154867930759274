<template>
  <div class="memoir-card">
    <div class="memoir-header">
      <h2 class="memoir-title">{{ memoir.title }}</h2>
      <div class="memoir-date">📅 {{ memoir.time }}</div>
    </div>
    <div class="memoir-content">
      <div v-for="(media, index) in memoir.img" :key="index" class="memoir-item">
        <div class="media-container">
          <template v-if="isImage(media)">
            <img
              :src="getFullImageUrl(media)"
              :alt="memoir.title"
              @click="$emit('preview-image', media)"
              @load="onImageLoad($event, index)"
              :class="imageAspectRatios[index]" />
          </template>
          <template v-else>
            <video controls class="video-player">
              <source :src="getFullImageUrl(media)" type="video/mp4" />
              您的浏览器不支持视频播放
            </video>
          </template>
        </div>
        <p v-if="memoir.description[index]" class="memoir-text">
          {{ memoir.description[index] }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  props: {
    memoir: {
      type: Object,
      required: true,
    },
  },
  emits: ['preview-image'],
  setup() {
    const imageAspectRatios = ref({})

    const isImage = file => {
      return /\.(jpg|jpeg|png|gif)$/i.test(file)
    }

    const getFullImageUrl = path => {
      return `https://hyljava.xuying.vip${path}`
    }

    const onImageLoad = (event, index) => {
      const img = event.target
      const ratio = img.naturalWidth / img.naturalHeight

      if (ratio >= 1.2) {
        // 宽图
        imageAspectRatios.value[index] = 'wide-image'
      } else if (ratio <= 0.8) {
        // 长图
        imageAspectRatios.value[index] = 'tall-image'
      } else {
        // 接近正方形的图
        imageAspectRatios.value[index] = 'square-image'
      }
    }

    return {
      imageAspectRatios,
      isImage,
      getFullImageUrl,
      onImageLoad,
    }
  },
}
</script>

<style lang="scss" scoped>
.memoir-card {
  background: #fff;
  border-radius: 20px;
  padding: 25px;
  margin: 20px 0;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease;
  border: 2px solid #ffd1dc;

  &:hover {
    transform: translateY(-5px);
  }

  .memoir-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .memoir-title {
      font-size: 1.5em;
      color: #ff69b4;
      margin: 0;
      font-weight: 600;
    }

    .memoir-date {
      color: #888;
      font-size: 0.9em;
      background: #fff5f7;
      padding: 5px 10px;
      border-radius: 12px;
    }
  }

  .memoir-content {
    .memoir-item {
      margin-bottom: 30px;

      .media-container {
        position: relative;
        border-radius: 15px;
        overflow: hidden;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        margin-bottom: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f8f8f8;

        img {
          max-width: 100%;
          height: auto;
          transition: transform 0.3s ease;
          cursor: pointer;

          &.wide-image {
            width: 100%;
            max-height: 500px;
            object-fit: contain;
          }

          &.tall-image {
            width: auto;
            max-height: 700px;
            object-fit: contain;
          }

          &.square-image {
            width: auto;
            max-height: 500px;
            object-fit: contain;
          }

          &:hover {
            transform: scale(1.02);
          }
        }

        .video-player {
          width: 100%;
          max-height: 500px;
          object-fit: contain;
          background: #000;
          border-radius: 15px;
        }
      }

      .memoir-text {
        font-size: 1.1em;
        line-height: 1.8;
        color: #666;
        background: #fff5f7;
        padding: 15px;
        border-radius: 12px;
        margin: 10px 0;
        text-align: justify;
      }
    }
  }
}

// 移动端适配
@media (max-width: 768px) {
  .memoir-card {
    padding: 15px;
    margin: 15px 0;
  }
}
</style>
