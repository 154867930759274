<template>
  <div class="loading-container">
    <div class="loading-spinner">
      <div class="heart"></div>
      <div class="loading-text">{{ text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '加载中...',
    },
  },
}
</script>

<style lang="scss" scoped>
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;

  .loading-spinner {
    text-align: center;

    .heart {
      display: inline-block;
      position: relative;
      width: 50px;
      height: 50px;
      transform: rotate(45deg);
      animation: heartBeat 2s ease-in-out infinite;
      background-color: #ff69b4;
      border-radius: 8px;

      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 50px;
        height: 50px;
        background-color: #ff69b4;
        border-radius: 50%;
      }

      &::before {
        left: -25px;
      }

      &::after {
        top: -25px;
      }
    }

    .loading-text {
      margin-top: 30px;
      color: #ff69b4;
      font-size: 1.2em;
    }
  }
}

@keyframes heartBeat {
  0% {
    transform: rotate(45deg) scale(0.8);
  }
  14% {
    transform: rotate(45deg) scale(1);
  }
  28% {
    transform: rotate(45deg) scale(0.8);
  }
  42% {
    transform: rotate(45deg) scale(1);
  }
  70% {
    transform: rotate(45deg) scale(0.8);
  }
  100% {
    transform: rotate(45deg) scale(0.8);
  }
}
</style>
