<template>
  <div class="home">
    <h1>回忆录</h1>
    <LoveTimer />
    <SearchBar :memoirs="memoirs" @search-results="handleSearchResults" />
    <div v-if="loading">
      <LoadingSpinner text="正在加载回忆录..." />
    </div>
    <div v-else-if="error" class="error">{{ error }}</div>
    <div v-else>
      <div class="memoirs-container">
        <template v-if="filteredMemoirs.length">
          <MemoirCard
            v-for="memoir in filteredMemoirs"
            :key="memoir.id"
            :memoir="memoir"
            @preview-image="showPreview" />
        </template>
        <EmptyState v-else title="暂无回忆" message="还没有找到相关的回忆内容~" />
      </div>
    </div>
    <ImagePreview
      v-model:visible="previewVisible"
      :images="filteredImages"
      :initial-index="currentImageIndex" />
    <BackToTop />
  </div>
</template>

<script>
import { onMounted, computed, ref } from 'vue'
import { useUsers } from '@/composables/useUsers'
import { useMemoirs } from '@/composables/useMemoirs'
import MemoirCard from '@/components/MemoirCard.vue'
import LoveTimer from '@/components/LoveTimer.vue'
import ImagePreview from '@/components/ImagePreview.vue'
import SearchBar from '@/components/SearchBar.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import EmptyState from '@/components/EmptyState.vue'
import BackToTop from '@/components/BackToTop.vue'

export default {
  components: {
    MemoirCard,
    LoveTimer,
    ImagePreview,
    SearchBar,
    LoadingSpinner,
    EmptyState,
    BackToTop,
  },
  setup() {
    const { users, loading: usersLoading, error: usersError, fetchUsers } = useUsers()
    const { memoirs, loading: memoirsLoading, error: memoirsError, fetchMemoirs } = useMemoirs()

    const loading = computed(() => usersLoading.value || memoirsLoading.value)
    const error = computed(() => usersError.value || memoirsError.value)

    const filteredMemoirs = ref([])

    // 图片预览相关
    const previewVisible = ref(false)
    const currentImageIndex = ref(0)

    // 获取所有图片
    const filteredImages = computed(() => {
      const images = []
      filteredMemoirs.value.forEach(memoir => {
        memoir.img.forEach(media => {
          if (/\.(jpg|jpeg|png|gif)$/i.test(media)) {
            images.push(`https://hyljava.xuying.vip${media}`)
          }
        })
      })
      return images
    })

    const showPreview = imagePath => {
      const fullPath = `https://hyljava.xuying.vip${imagePath}`
      const index = filteredImages.value.indexOf(fullPath)
      if (index !== -1) {
        currentImageIndex.value = index
        previewVisible.value = true
      }
    }

    const handleSearchResults = results => {
      filteredMemoirs.value = results
    }

    onMounted(async () => {
      await fetchUsers()
      if (users.value && users.value.length > 0) {
        const firstUser = users.value[0]
        await fetchMemoirs(firstUser.user)
        filteredMemoirs.value = memoirs.value
      }
    })

    return {
      loading,
      error,
      memoirs,
      filteredMemoirs,
      previewVisible,
      currentImageIndex,
      filteredImages,
      showPreview,
      handleSearchResults,
    }
  },
}
</script>

<style lang="scss" scoped>
.home {
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }

  h1 {
    text-align: center;
    color: #ff69b4;
    margin-bottom: 20px;
    font-size: 2em;
  }

  .memoirs-container {
    max-width: 800px;
    margin: 0 auto;
  }

  .error {
    text-align: center;
    padding: 20px;
    margin: 20px auto;
    max-width: 800px;
    color: #ff4444;
    background: #ffe6e6;
    border-radius: 12px;
  }
}
</style>
