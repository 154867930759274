import { ref } from 'vue'
import { userService } from '@/utils/request'

export function useUsers () {
  const users = ref([])
  const loading = ref(false)
  const error = ref(null)

  const fetchUsers = async () => {
    users.value = []
    error.value = null
    loading.value = true

    try {
      const response = await userService.get('/user.json')
      await new Promise(resolve => setTimeout(resolve, 500))
      users.value = response.data || []
    } catch (e) {
      error.value = '获取用户数据失败'
      console.error(e)
    } finally {
      loading.value = false
    }
  }

  return {
    users,
    loading,
    error,
    fetchUsers
  }
} 