<template>
  <div class="empty-state">
    <div class="empty-icon">📝</div>
    <h3>{{ title }}</h3>
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '暂无数据',
    },
    message: {
      type: String,
      default: '这里还没有任何内容哦~',
    },
  },
}
</script>

<style lang="scss" scoped>
.empty-state {
  text-align: center;
  padding: 40px 20px;
  background: #fff;
  border-radius: 20px;
  border: 2px dashed #ffd1dc;
  margin: 20px 0;

  .empty-icon {
    font-size: 3em;
    margin-bottom: 15px;
    animation: float 3s ease-in-out infinite;
  }

  h3 {
    color: #ff69b4;
    margin-bottom: 10px;
    font-size: 1.4em;
  }

  p {
    color: #666;
    font-size: 1.1em;
  }
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
</style>
